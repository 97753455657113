@import "src/scss/mixin";
@import "src/scss/vars";

.home-finished{
  padding-top: 64px;
  width: 100%;
  &__wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 24px;
  }
  &__item{
    &-wrapper{
      width: calc(100% + 16px);
      margin-left: -8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-wrap{
      max-width: 25%;
      flex: 0 0 25%;
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 16px;
    }

    height: 100%;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 1px 1px 3px 1px #0000001C;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    &-title{
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.01em;
      text-align: center;
      margin-bottom: 24px;
    }
    a.app-button .app-button__content {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 1024px){
  .home-finished{
    &__item{
      &-wrap{
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }
}


@media screen and (max-width: 768px){
  .home-finished{
    padding-top: 48px;
    &__title{
      font-size: 24px;
      line-height: 40px;
    }
    &__item{
      &-wrapper{
        width: 100%;
        margin-left: 0;
      }
      &-wrap{
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}