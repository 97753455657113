@import "src/scss/vars";
@import "src/scss/mixin";

.home {
  position: relative;
  padding-top: 114px;
  &__slider {
    padding: 64px 0;
  }
}

@media screen and (max-width: 1024px) {
  .home {
    padding-top: 84px;
    &__slider {
      padding: 36px 0;
    }
  }
}
