@import "src/scss/mixin";
@import "src/scss/vars";

.home-world {
  .app__wrapper {
    width: 100%;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    //min-height: 300px;
    padding-top: 175px;
    padding-bottom: 195px;
  }

  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    width: calc(100% + 520px);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url('../../../assets/images/WorldMap.png') center center no-repeat;
    background-size: contain;
    opacity: .5;
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 150px;
    min-width: 150px;
    max-width: 800px;
    margin: 0 auto;
    padding: 8px 128px 8px 8px;
    background: #F3F4F6;
    border: 8px solid $c-white;
    border-radius: 600px;
    box-shadow: 0 0 1px 1px #CAE2E6;
    overflow: hidden;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    padding: 0 32px;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 8px;
  }

  &__item-title {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 40px;
    line-height: 160%;
    letter-spacing: 0.05em;
    color: $c-turquoise;
  }

  &__item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: $c-black;
    text-align: center;
  }

  &__content-img {
    position: absolute;
    right: 8px;
    top: 8px;
    max-width: 120px;

    img {
      display: block;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-world__item-title {
    font-size: 32px;
  }
  .home-world__item-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .home-world__inner {
    min-height: 275px;
  }
  .home-world__bg {
    //width: calc(100% + 300px);
  }
  .home-world__content-img {
    display: none;
  }
  .home-world__content {
    height: 120px;
    padding: 8px 4px;
    border-radius: 16px;
    border-width: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.03), 0px 1px 3px rgba(0, 0, 0, 0.01);
  }
  .home-world__list {
    padding: 0;
  }

  .home-world__list-item {
    padding: 0;
  }

  .home-world__inner {
    margin: 0 -8px;
  }
}
@media screen and (max-width: 480px) {
  .home-world__inner {
    min-height: 230px;
  }
}
