/*Kumbh Sans*/
@font-face {
  font-family: 'Kumbh Sans';
  src: url('../assets/fonts/KumbhSansExtraBold.eot');
  src: url('../assets/fonts/KumbhSansExtraBold.eot') format('embedded-opentype'),
  url('../assets/fonts/KumbhSansExtraBold.woff2') format('woff2'),
  url('../assets/fonts/KumbhSansExtraBold.woff') format('woff'),
  url('../assets/fonts/KumbhSansExtraBold.ttf') format('truetype'),
  url('../assets/fonts/KumbhSansExtraBold.svg#KumbhSansExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../assets/fonts/KumbhSansSemiBold.eot');
  src: url('../assets/fonts/KumbhSansSemiBold.eot') format('embedded-opentype'),
  url('../assets/fonts/KumbhSansSemiBold.woff2') format('woff2'),
  url('../assets/fonts/KumbhSansSemiBold.woff') format('woff'),
  url('../assets/fonts/KumbhSansSemiBold.ttf') format('truetype'),
  url('../assets/fonts/KumbhSansSemiBold.svg#KumbhSansSemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../assets/fonts/KumbhSansMedium.eot');
  src: url('../assets/fonts/KumbhSansMedium.eot') format('embedded-opentype'),
  url('../assets/fonts/KumbhSansMedium.woff2') format('woff2'),
  url('../assets/fonts/KumbhSansMedium.woff') format('woff'),
  url('../assets/fonts/KumbhSansMedium.ttf') format('truetype'),
  url('../assets/fonts/KumbhSansMedium.svg#KumbhSansMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../assets/fonts/KumbhSansRegular.eot');
  src: url('../assets/fonts/KumbhSansRegular.eot') format('embedded-opentype'),
  url('../assets/fonts/KumbhSansRegular.woff2') format('woff2'),
  url('../assets/fonts/KumbhSansRegular.woff') format('woff'),
  url('../assets/fonts/KumbhSansRegular.ttf') format('truetype'),
  url('../assets/fonts/KumbhSansRegular.svg#KumbhSansRegular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
