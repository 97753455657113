@import "src/scss/mixin";
@import "src/scss/vars";

.home-activity{
  width: 100%;
  padding-top: 116px;
  &__wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 64px;
    span{
      color: $c-ua-hg-prymary-100;
    }
  }
  &__content{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    &-left{
      width: 100%;
      &__item{
        box-shadow: 0px 5.398838043212891px 11.925545692443848px 0px #00000008, 0px 8px 28.20417022705078px 0px #00000008, 0px 25.104999542236328px 93.35497283935547px 0px #0000000D, 0px 39.417171478271484px 160.33148193359375px 0px #0000000F;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 16px;
        padding: 42px;
        border: 2px solid $c-ua-hg-prymary-100;
        &-connector{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        &-head{
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 8px;
          &-left{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-width: calc(100% - 140px);
            &-date{
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
              text-align: left;
              color: $c-black--150;
              margin-bottom: 8px;
            }
            &-title{
              font-size: 36px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 0.02em;
              text-align: left;
            }
          }
          &-right{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            a{
              margin-top: 8px;
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
              text-decoration: underline;
              cursor: pointer;
              color: $c-ua-hg-prymary-135;
            }
          }
        }
        &-desc{
          width: 100%;
          font-size: 18px;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 0.01em;
          text-align: left;
          margin-bottom: 16px;
        }
        &-image{
          width: 100%;
          height: 500px;
          overflow: hidden;
          border-radius: 16px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      display: flex;
      align-self: stretch;
    }
    &-right{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 32px;
      align-self: stretch;
      &__item{
        min-height: calc(33.333% - 22px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 42px;
        border-radius: 16px;
        width: 100%;
        box-shadow: 0px 5.398838043212891px 11.925545692443848px 0px #00000008, 0px 8px 28.20417022705078px 0px #00000008, 0px 25.104999542236328px 93.35497283935547px 0px #0000000D, 0px 39.417171478271484px 160.33148193359375px 0px #0000000F;
        &-text{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          &__title{
            font-size: 36px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0.02em;
            text-align: left;
            margin-bottom: 8px;
          }
          &__desc{
            font-size: 18px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: 0.01em;
            text-align: left;
          }
        }
        &-status{
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0.01em;
          text-align: left;
        }
      }
    }
  }

}

@media screen and (max-width: 1024px){
  .home-activity{
    &__content{
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 768px){
  .home-activity{
    padding-top: 48px;
    &__title{
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 48px;
    }
    &__content{
      &-left{
        &__item{
          width: 100%;
          &-head{
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 16px;
            &-left{
              max-width: none;
              &-title{
                font-size: 24px;
                line-height: 40px;
                margin-bottom: 8px;
              }
            }
          }
          &-desc{
            font-size: 16px;
            line-height: 28px;
          }
          &-image{
            height: 244px;
          }
        }
      }
      &-right{
        &__item{
          &-text{
            &__title{
              font-size: 24px;
              line-height: 40px;
            }
            &__desc{
              font-size: 16px;
            }
          }
          &-status{
            font-size: 16px;
          }
        }
      }
    }
  }
}