@import "src/scss/vars";
@import "src/scss/mixin";

.terms {
  width: 100%;
  position: relative;
  padding-top: 200px;
  padding-bottom: 120px;
  &__wrap{
    width: 100%;
  }
  &__title{
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 30px;
  }
  &__content{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    b, strong{
      font-weight: 700;
    }
    i, em{
      font-style: italic;
    }
    ul{
      list-style-type: disc;
    }
    ol{
      list-style-type: decimal;
    }
    a{
      text-decoration: underline;
      cursor: pointer;
      color: $c-ua-hg-prymary-100;
    }
  }
}

@media screen and (max-width: 1024px) {
  .terms {
    padding-top: 100px;
    padding-bottom: 100px;
    &__title{
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 24px;
    }
  }
}
