@import "src/scss/mixin";
@import "src/scss/vars";

.home-partners{
  padding-top: 64px;
  width: 100%;
  &__caption{
    max-width: 800px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 32px;
  }
  &__wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0.03em;
    text-align: center;
    margin-bottom: 32px;
  }
  &__item{
    &-wrapper{
      max-width: 842px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-wrap{
      max-width: 25%;
      flex: 0 0 25%;
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 16px;
    }

    text-decoration: none;
    cursor: pointer;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 1px 1px 3px 1px #0000001C;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1024px){
  .home-partners{
    &__title{
      font-size: 38px;
      line-height: 42px;
    }
    &__item{
      padding: 8px;
      height: 48px;
    }
  }
}


@media screen and (max-width: 768px){
  .home-partners{
    padding-top: 48px;
    &__title{
      font-size: 24px;
      line-height: 40px;
    }
    &__item{
      &-wrap{
        max-width: 33.333%;
        flex: 0 0 33.333%;
        padding-left: 4px;
        padding-right: 4px;
        margin-bottom: 8px;
      }
    }
  }
}