[data-a] {
  transition-duration: .6s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}

[data-a-delay="1200"].a-animate {
  transition-delay: 1.2s;
}

[data-a-delay="1000"].a-animate {
  transition-delay: 1s;
}

[data-a-delay="800"].a-animate {
  transition-delay: .8s;
}

[data-a-delay="600"].a-animate {
  transition-delay: .6s;
}

[data-a-delay="400"].a-animate {
  transition-delay: .4s;
}

[data-a-delay="200"].a-animate {
  transition-delay: .2s;
}


[data-a^=zoom][data-a^=zoom].a-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0,100px,0) scale(.6);
}

[data-a=zoom-in] {
  transform: scale(.6);
}

[data-a^=zoom] {
  opacity: 0;
}

[data-a^=fade][data-a^=fade].a-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-a^=fade] {
  opacity: 0;
}

[data-a=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-a=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-a=fade-left] {
  transform: translate3d(100px, 0, 0);
}
//--------------------------------
[data-aos="custom-zoom-in"] {
  transform: scale(.2);
  top: -61%;
  left: -76%;
  transition-property: transform, opacity, top, left;
  &.aos-animate {
    transform: scale(1);
    top: -40%;
    left: -37%;
  }
}

[data-aos="custom-slide-right"] {
  width: 12.5%;
  transition-property: width;
  &.aos-animate {
    width: 100%;
  }
}

[data-aos][data-aos].aos-animate{
  //duration
  &[data-aos-duration="6800"] {
    transition-duration: 6.8s;
  }
  &[data-aos-duration="8600"] {
    transition-duration: 8.6s;
  }
  //delay
  &[data-aos-delay="3100"] {
    transition-delay: 3.1s;
  }
  &[data-aos-delay="3450"] {
    transition-delay: 3.45s;
  }
  &[data-aos-delay="3800"] {
    transition-delay: 3.8s;
  }
  &[data-aos-delay="4150"] {
    transition-delay: 4.15s;
  }
  &[data-aos-delay="4500"] {
    transition-delay: 4.5s;
  }
  &[data-aos-delay="4600"] {
    transition-delay: 4.6s;
  }
  &[data-aos-delay="4850"] {
    transition-delay: 4.85s;
  }
  &[data-aos-delay="4900"] {
    transition-delay: 4.9s;
  }
  &[data-aos-delay="5000"] {
    transition-delay: 5s;
  }
  &[data-aos-delay="5200"] {
    transition-delay: 5.2s;
  }
  &[data-aos-delay="5300"] {
    transition-delay: 5.3s;
  }
}
