@import "src/scss/mixin";
@import "src/scss/vars";

.expander {

  @include trCubicFast();
  border-bottom: 1px solid $c-black--125;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    color: $c-black;
    cursor: pointer;
  }
  &__title {
    padding: 16px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    text-align: left;
    @include trCubicFast();
  }
  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }
  &__body {
    margin-top: -8px;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    color: $c-black;
  }
}


//@media screen and (max-width: 768px){

//



