@import "src/scss/mixin";
@import "src/scss/vars";

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  li {

  }
  a {
    flex-grow: 1;
    @include trCubic();
    color: $c-black;
    text-decoration: none;
    &:hover,&:active {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px){
  .header-nav {
    flex-direction: column;
    align-items: flex-start;
    li {
      width: 100%;
    }
    li + li {
      margin-top: 16px;
    }
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 40px;
      padding: 3px 16px;
      color: $c-black;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      box-sizing: border-box;
      @include trEaseInOut();
      &:hover {
        background: $c-gray;
        border: 1px solid $c-gray;
        text-decoration: none;
      }

      &:active {
        color: $c-white;
        background: $c-ua-hg-prymary-150;
        border: 1px solid $c-ua-hg-prymary-150;
        text-decoration: none;
      }
    }
  }
}
