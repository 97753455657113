@import "src/scss/mixin";
@import "src/scss/vars";

.how-modal {
  max-width: 895px;
  padding: 56px 6px 24px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 28px 0 34px;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 133%;
    color: $c-black;
    text-align: center;
  }
  &__schema{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  &__info {}
  &__info-title {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 167%;
    letter-spacing: 0.02em;
    color: $c-black;
    border-bottom: 1px solid $c-bwl-400-d-600;
  }
  &__info-text {
    font-size: 16px;
    line-height: 175%;
    color: $c-black;
    ul{
      li {
        position: relative;
        padding-left: 32px;
        & + & {
          margin-top: 12px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 24px;
          height: 24px;
          background: url('../../../assets/images/down-right.png') center center no-repeat;
          background-size: cover;
        }
      }
    }
  }
  &__info-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 24px -21px 0;
  }
  &__info-column {
    width: 50%;
    padding: 0 21px;
  }
  &__info-subtitle {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 167%;
    letter-spacing: 0.02em;
    color: $c-bwl-500-d-500;
  }
}


@media (max-width: 768px) {
  .how-modal {
    max-width: 100%;
    padding: 32px 6px 94px;
  }
  .how-modal__content {
    padding: 0 4px 0 10px;
  }
  .how-modal__title{
    margin-bottom: 16px;
    font-size: 24px;
  }
  .how-modal__info-title{
    font-size: 18px;
    border-bottom: none;
  }
  .how-modal__info-subtitle{
    font-size: 18px;
  }
  .how-modal__info-grid {
    flex-direction: column;
    margin: 24px 0 0;
  }
  .how-modal__info-column{
    width: 100%;
    padding: 0;
    & + & {
      margin-top: 32px;
    }
  }
}
