@import "src/scss/mixin";
@import "src/scss/vars";

.activity-modal {
  max-width: 775px;
  padding: 56px 6px 24px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 28px 0 34px;
    &-head{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 17px;
      &-left{
        max-width: calc(100% - 368px);
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        &-date{
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          color: $c-black--150;
          margin-bottom: 8px;
        }
        &-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0.02em;
          text-align: left;
          margin-bottom: 8px;
        }
        &-link{
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          text-decoration: underline;
          cursor: pointer;
          color: $c-ua-hg-prymary-135;
        }
      }
      &-right{
        overflow: hidden;
        border-radius: 16px;
        max-width: 344px;
        height: 344px;
        width: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &-desc{
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      a{
        margin-left: 5px;
        color: $c-bwl-800-d-200;
        display: inline-block;
      }
    }
  }
}


@media (max-width: 768px) {
  .activity-modal {
    max-width: 100%;
    padding: 32px 6px 94px;
  }
  .activity-modal__content {
    padding: 0 4px 0 10px;
    &-head{
      padding-top: 10px;
      flex-direction: column;
      &-left{
        max-width: none;
        &-title{
          font-size: 24px;
          line-height: 40px;
        }
      }
      &-right{
        max-width: none;
        height: 270px;
      }
    }
  }
}
