//---COLORS

//NOTE: 100 - default, >100 - darkest, <100 - lightest,
// step - 25 [0,25,50,75,100,125,150,175,200] shades

$c-white: #ffffff;
$c-white-opacity: rgba($c-white, .4);
$c-black: #444040;
$c-black-opacity: rgba($c-black, .4);
$c-black--125: #03131F;
$c-black--150: #000000;
$c-bwl-600-d-400: #6D6D6D;
$c-bwl-700-d-300: #403F3F;
$c-bwl-800-d-200: #302F2F;
$c-bwl-900-d-100: #2A2927;
$c-orange: #FF7B56;
$c-orange--125: #FB4E1E;
$c-orange--150: #CD2E01;
$c-ua-hg-prymary-100: #0790F1;
$c-ua-hg-prymary-125: #0071F2;
$c-ua-hg-prymary-135: #376FE5;
$c-ua-hg-prymary-150: #054997;
$c-yellow--75: #FAD400;
$c-yellow: #E5A800;
$c-gray--75: #F3F4F6;
$c-gray: #E0E1E3;
$c-bwl-400-d-600: #CACBCE;
$c-bwl-500-d-500: #939393;

$c-turquoise: #638E9C;
$c-turquoise-125: #3B6895;

$c-error: #FC3A25;
$c-info: #2196f3;
$c-success: #3bdc42;
$c-warning: #ffc107;

$c-primary: $c-orange;
$c-primary--125: $c-orange--125;
$c-primary--150: $c-orange--150;
$c-secondary: $c-ua-hg-prymary-100;
$c-secondary--125: $c-ua-hg-prymary-125;
$c-secondary--150: $c-ua-hg-prymary-150;
$c-accent: $c-yellow;

//---ANIMATIONS
@keyframes gradient-animation {
  0% {
    background-position: 0% 504px;
  }
  100% {
    background-position: 0% 0px;
  }
}


//---TRANSITION
$trans-time: .4s;
$trans-time--fast: .3s;
$trans-time--slow: .6s;

//---FONTS-FAMILY
$f-family-museo: 'MuseoModerno', sans-serif;
$f-family-kyiv: 'KyivType Sans', sans-serif;


@mixin shadow-block--big() {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 4%);
}

@mixin shadow-block--small() {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);
}


//@mixin gradient--green() {
//  background-image: linear-gradient(310deg,$color-success,#3BDC42FF);
//}

@mixin margin-center() {
  margin-left: auto;
  margin-right: auto
}

@mixin list-reset-style() {
  list-style: none !important;
  padding-left: 0 !important;
  color: lightgray;
}

@mixin text-dots($line-clamp: 1) {
  @if $line-clamp <= 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin fs-lh($fs: 14px, $lh: 24px) {
  font-size: $fs;
  line-height: $lh;
}

@mixin tr-cb($time: .4s) {
  transition: all $time cubic-bezier(.25, .8, .25, 1)
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    width: 6px;
    background: $c-gray--75;
    border-left: 2px solid $c-white;
    border-right: 2px solid $c-white;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: $c-bwl-400-d-600;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    width: 6px;
    background: $c-bwl-400-d-600;
    border-radius: 3px;
  }
}
