@import "src/scss/mixin";
@import "src/scss/vars";

.home-faqs {
  padding: 120px 0;
  color: $c-black;
  &__caption{
    margin-top: 60px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  &__grid {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__aside {
    margin-right: 10%;
  }
  &__title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 36px;
    line-height: 133%;
    letter-spacing: 0.02em;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 184px;
      border-bottom: 4px solid $c-orange;
    }

  }
  &__text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 175%
  }
  &__link {
    display: inline-flex;
    align-items: center;

    text-decoration: none;
    @include trCubic();
    color: $c-ua-hg-prymary-125;
    svg {
      margin-left: 8px;
    }
    &:hover,&:active {
      text-decoration: underline;
    }
  }
  &__content {
    flex-grow: 1;
    width: 100%;
    max-width: 736px;
  }
  &__expander {
    border-top: 1px solid $c-black--125;
  }
  &__polygon {
    position: absolute;
    top: -90px;
    right: -80px;
  }
}

@media screen and (max-width: 1360px){
  .home-faqs__polygon {
    right: 32px;
  }
}

@media screen and (max-width: 1024px){
  .home-faqs__title {
    padding-bottom: 12px;
    font-size: 24px;
    &:after {
      width: 152px;
    }
  }
  .home-faqs__aside {
    margin-right: 16px;
  }
}
@media screen and (max-width: 768px){
  .home-faqs__grid {
    flex-direction: column;
  }
  .home-faqs__aside {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
