@import "scss/fonts";
@import "scss/vars";
@import "scss/mixin";
@import "scss/reset";
@import "scss/modal";
@import "scss/form";
@import "scss/anim";

:root {
  --c-white: $c-white;
  --c-black: $c-white;
  --c-primary: $c-primary;
  --c-secondary: $c-secondary;
  --c-accent: $c-accent;
  --c-error: $c-error;
  --c-info: $c-info;
  --c-succes: $c-success;
  --c-warning: $c-warning;
}

html,body {
  color: $c-black;
  background-color: $c-white;
}

body {
  margin: 0;
  font-family: 'Kumbh Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.body--fixed {
     position: static;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     overflow: hidden;
   }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  cursor: pointer;
}

b,strong {
  font-weight: 600;
}

.app-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;

  &__top {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
    flex-shrink: 0
  }

  &__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__bottom {
    width: 100%;
    flex-shrink: 0;
  }
}

.app__wrapper {
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}
