@import "src/scss/mixin";
@import "src/scss/vars";

.home-main {
  width: 100%;
  background: $c-white;
  background-size: cover;
  margin-top: 40px;
  &__wrap{
    padding: 60px 60px 80px 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EFF7FE;
    border-radius: 16px;
  }
  &__content{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 605px;
    &-title{
      font-size: 64px;
      font-weight: 700;
      line-height: 80px;
      letter-spacing: 0.03em;
      text-align: left;
      margin-bottom: 16px;
      color: $c-bwl-800-d-200;
    }
    &-desc{
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.02em;
      text-align: left;
      color: $c-bwl-800-d-200;
      b, strong{
        font-weight: 700;
      }
    }
    &-btn{
      margin-top: 30px;
    }
  }
  &__banner{
    max-width: 420px;
    width: 100%;
    height: 420px;
    border-radius: 16px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px){
  .home-main{
    &__wrap{
      padding: 30px 30px 50px 30px;
    }
    &__content{
      max-width: 350px;
      &-title{
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 16px;
      }
      &-desc{
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .home-main{
    &__wrap{
      padding: 25px 15px;
      flex-direction: column;
    }
    &__content{
      max-width: none;
      margin-bottom: 32px;
      &-btn{
        width: 100%;
        button{
          width: 100%;
        }
      }
    }
    &__banner{
      max-width: none;
      height: auto;
      img{
        height: auto;
      }
    }
  }
}
