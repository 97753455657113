@import "src/scss/mixin";
@import "src/scss/vars";

.contact-us-modal {
  max-width: 410px;
  padding: 24px 32px 40px;

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    text-align: center;
    color: $c-black;
  }

  &__form {
  }

  &__field {
    & + & {
      margin-top: 12px;
    }
  }

  &__actions {
    margin-top: 32px;
  }

  &__btn {
    width: 100%;
    & + & {
      margin-top: 24px;
    }
  }

  &__message-text {
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 175%;
    color: $c-black;
  }
}

@media screen and (max-width: 768px) {
  .contact-us-modal {
    max-width: 100%;
    padding: 24px 16px 110px;
  }
}
