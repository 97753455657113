@import "src/scss/mixin";
@import "src/scss/vars";

.app-form {
  &__field {
    input, textarea {
      width: 100%;
      padding: 9px 12px;
      font-size: 16px;
      line-height: 175%;
      color: $c-bwl-500-d-500;
      background: $c-white;
      border: 1px solid $c-gray;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.03), 0px 1px 3px rgba(0, 0, 0, 0.01);
      border-radius: 4px;
      @include trCubic();

      &::-webkit-input-placeholder {
        color: $c-bwl-500-d-500;
      }
      &:hover {
        border: 1px solid $c-bwl-500-d-500;
        outline: $c-bwl-500-d-500;
      }
      &:focus {
        border: 1px solid $c-yellow--75;
        outline: $c-yellow--75;
      }

      &.app-form__field--error {
        border: 1px solid $c-error;
      }
    }

    label {
      display: block;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 133%;
      color: $c-bwl-500-d-500;
    }

    &--input {

    }

    &--textarea {

    }

    &-error {
      padding-top: 8px;
      color: $c-error;
      font-size: 12px;
      line-height: 16px;
      @include trCubic();
    }
  }
}
