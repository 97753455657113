@import "src/scss/mixin";
@import "src/scss/vars";

.home-cta{
  width: 100%;
  background: $c-ua-hg-prymary-100;
  &__wrap{
    padding-top: 48px;
    padding-bottom: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    &-title{
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #FFFFFF;
    }
    &-desc{
      font-size: 24px;
      font-weight: 300;
      line-height: 40px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #FFFFFF;
    }
  }
}

@media screen and (max-width: 768px){
  .home-cta{
    &__wrap{
      flex-direction: column;
    }
    &__left{
      justify-content: center;
      align-items: center;
      &-title, &-desc{
        text-align: center;
      }
      &-title{
        font-size: 24px;
        line-height: 40px;
      }
      &-desc{
        font-size: 18px;
        line-height: 28px;
      }
    }
    &__right{
      margin-top: 16px;
    }
  }
}