@import "src/scss/mixin";
@import "src/scss/vars";

.header {
  color: $c-black;
  backdrop-filter: blur(8px);
  background-color: rgba(255,255,255,0.2);
  &__progress {
    height: 8px;
  }
  &__progress-bar {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #FED1BE 0%,#6DB3F8 10% ,#6DB3F8 100%);
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1352px;
    margin: 0 auto;
    padding: 32px 0;
  }
  &__nav {
    max-width: 620px;
    width: 100%;
    margin: 0 24px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__btn{
    min-width: 115px;
    margin-left: 15px;
  }
  &__btn-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    &--active{
      color: $c-white!important;
      background: $c-ua-hg-prymary-150!important;
      border: 1px solid $c-ua-hg-prymary-150!important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header__content {
    padding: 16px 0;
  }
}
