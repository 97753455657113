@import "src/scss/mixin";
@import "src/scss/vars";

.home-how{
  width: 100%;
  padding-top: 68px;
  padding-bottom: 64px;
  &__wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0.03em;
    text-align: center;
    margin-bottom: 64px;
  }
  &__content{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    &-left{
      width: 60%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      align-self: stretch;
      gap: 32px;
      &__item{
        height: 100%;
        width: 100%;
        padding: 42px;
        border-radius: 16px;
        box-shadow: 0px 5.398838043212891px 11.925545692443848px 0px #00000008, 0px 8px 28.20417022705078px 0px #00000008, 0px 25.104999542236328px 93.35497283935547px 0px #0000000D, 0px 39.417171478271484px 160.33148193359375px 0px #0000000F;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        &-connector{
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
        &-btn{
          margin-top: 32px;
          //display: flex;
          //align-items: flex-start;
          //justify-content: flex-start;
          //flex-direction: column;
          //gap: 16px;
        }
        &-text{
          max-width: 437px;
        }
        &-step{
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          color: $c-ua-hg-prymary-100;
        }
        &-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0.02em;
          text-align: left;
          margin-bottom: 8px;
        }
        &-desc{
          font-size: 16px;
          font-weight: 300;
          line-height: 28px;
          text-align: left;
        }
      }
    }
    &-right{
      width: 40%;
      display: flex;
      align-self: stretch;
      &__item{
        width: 100%;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        box-shadow: 0px 5.398838043212891px 11.925545692443848px 0px #00000008, 0px 8px 28.20417022705078px 0px #00000008, 0px 25.104999542236328px 93.35497283935547px 0px #0000000D, 0px 39.417171478271484px 160.33148193359375px 0px #0000000F;
        padding: 42px;
        &-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0.02em;
          text-align: center;
          margin-bottom: 12px;
        }
        &-input{
          width: 100%;
          margin-bottom: 32px;
          label{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            &:first-child{
              position: relative;
              &:after{
                bottom: calc(50% - 9px);
                transform: translateY(50%);
                right: 10px;
                position: absolute;
                content: 'UAHg';
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
              }
            }
            &>div{
              width: 100%;
            }
            .css-13cymwt-control:hover{
              border-color: #E0E1E3;
            }
            .css-1fdsijx-ValueContainer{
              padding: 11px 8px;
            }
            .css-1u9des2-indicatorSeparator{
              display: none;
            }
            .css-t3ipsp-control{
              &:hover{
                border-color: #E0E1E3;
              }
              border-color: #E0E1E3;

            }
            span{
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
              color: $c-bwl-500-d-500;
              margin-bottom: 4px;
            }
          }
          label + label{
            margin-top: 12px;
          }
          &-container{
            width: 100%;
            position: relative;
          }
          &-input{
            width: 100%;
            border: 1px solid #E0E1E3;
            box-shadow: 0px 1px 3px 0px #00000003, 0px 1px 3px 0px #00000008, 0px 4px 12px 0px #0000000A;
            padding: 10px 12px;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            border-radius: 4px;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &[type=number] {
              -moz-appearance: textfield;
            }
          }
          &-range{
            position: absolute;
            left: 0;
            bottom: 0px;
            right: 0;
            margin: 0;
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            cursor: pointer;
            width: 100%;
            border-radius: 6px;
            &::-webkit-slider-runnable-track {
              background: $c-ua-hg-prymary-100;
              height: 2px;
               border-radius: 4px;
            }
            &::-moz-range-track {
              background: $c-ua-hg-prymary-100;
              height: 2px;
               border-radius: 4px;
            }
            &::-webkit-slider-thumb {
              -webkit-appearance: none; /* Override default look */
              appearance: none;
              margin-top: -4px; /* Centers thumb on the track */
              border-radius: 50%;
              background-color: $c-white;
              height: 10px;
              width: 10px;
              border: 2px solid $c-ua-hg-prymary-100;
            }
          }
        }

        &-card{
          width: 100%;
          height: 286px;
          background: #EFF7FE;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 30px;
          border-radius: 16px;
          &-title{
            font-size: 36px;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: 0.02em;
            text-align: center;
            margin-bottom: 8px;
          }
          &-value{
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              font-size: 48px;
              font-weight: 700;
              line-height: 57.6px;
              letter-spacing: 0.02em;
              margin-right: 16px;
            }
            svg{
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .home-how{
    &__title{
      font-size: 48px;
      line-height: 55px;
      margin-bottom: 32px;
    }
    &__content{
      flex-direction: column;
      &-left, &-right{
        width: 100%;
      }
      &-left{
        &__item{
          &-text{
            max-width: calc(100% - 200px);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .home-how{
    padding-top: 48px;
    padding-bottom: 48px;
    &__title{
      font-size: 36px;
      line-height: 48px;
    }
    &__content{
      &-left{
        &__item{
          padding: 24px;
          &-btn{
            margin-top: 24px;
          }
          &-title{
            font-size: 24px;
            line-height: 40px;
          }
          &-connector{
            flex-direction: column;
          }
          &-text{
            max-width: none;
          }
        }
      }
      &-right{
        &__item{
          padding: 24px;
          &-input{
            &-range{
              &::-webkit-slider-thumb{
                height: 15px;
                width: 15px;
                margin-top: -7px;
              }
            }
          }
          &-card{
            height: 270px;
            &-title{
              font-size: 24px;
              line-height: 40px;
            }
            &-value{
              span{
                font-size: 36px;
                line-height: 40px;
                margin-right: 8px;
              }
              svg{
                width: 32px;
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
}