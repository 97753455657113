@import "src/scss/mixin";
@import "src/scss/vars";

.footer {
  position: relative;
  padding: 36px 0;
  font-size: 12px;
  line-height: 133%;
  color: $c-gray--75;
  background-color: $c-black--125;
  text-align: center;
  &__grid {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  &__logo {
    margin-bottom: 28px;
  }

  &__left {
  }

  &__center {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    ul + ul {
      margin-left: 90px;
    }
  }

  &__links {
    li {

    }

    li + li {
      margin-top: 20px;
    }

    a {
      display: inline-block;
      color: $c-gray--75;
      @include trCubic();
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }
    }

  }

  &__socials {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 24px;
    max-width: 465px;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: calc(20% - 48px);
    }
    a {
      display: inline-block;
      margin-top: 12px;
      color: $c-gray--75;
      @include trCubic();
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }

  &__right {
  }
}

@media screen and (max-width: 1140px) {
  .footer__center {
    ul + ul {
      margin-left: 36px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer{
    padding: 40px 0 32px;
  }

  .footer__grid {
    flex-wrap: wrap;
  }

  .footer__right {
    width: 100%;
    margin-top: 56px;
  }

  .footer__socials {
    margin: 0 auto;
  }



}

@media screen and (max-width: 768px) {
  .footer{
    padding: 40px 0 32px;
  }

  .footer__grid {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }

  .footer__left {
    order: 3;
    align-self: center;
    margin-top: 56px;
  }

  .footer__logo {
    margin-bottom: 24px;
  }

  .footer__center {
    justify-content: space-evenly;
    width: 100%;
    max-width: 465px;
    ul + ul {
      margin-left: 60px;
    }
  }
}

@media screen and (max-width: 480px){
  .footer__socials {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    li {
      flex-direction: row;
    }

    li + li {
      margin-top: 24px;
      margin-left: 0;
    }

    a {
      margin-top: 0;
      margin-left: 24px;
    }
  }
}
