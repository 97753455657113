@import "src/scss/mixin";
@import "src/scss/vars";

.home-about {
  position: relative;
  padding-top: 170px;
  padding-bottom: 140px;
  color: $c-black;
  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    //width: calc(100% + 520px);
    height: 100%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background: url('../../../assets/images/matrix.png') center center repeat-x;
    background-size: contain;

    &--1 {
      animation: matrixFade 4s infinite;
    }
    &--2 {
      top: 16px;
      animation: matrixFade 4s infinite;
      animation-delay: 3s;
    }
  }
  @keyframes matrixFade {
    0% {opacity: 0;}
    25% {opacity: 1;}
    75% {opacity: 1;}
    100% {opacity: 0;}
  }

  &__grid {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: $c-black;
  }
  &__content {
    width: 100%;
    &-container{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  &__title {
    margin-bottom: 46px;
    font-weight: 400;
    font-size: 36px;
    line-height: 133%;
  }
  &__box {
    margin-right: 24px;
    width: 100%;
    max-width: 636px;
    padding: 32px 40px;
    font-size: 16px;
    line-height: 175%;
    background-color: $c-white;
    box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.09), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 1.12694px 2.75474px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  &__box-text {
  }
  &__box-link {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
    color: $c-ua-hg-prymary-125;
    &:hover,&:active {

    }
  }
  &__box-btn {
    margin-top: 24px;
    width: 240px;
  }
  &__points{
    align-self: stretch;
    max-width: 512px;
    width: 100%;
    img{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px){
  .home-about__content{
    &-container{
      flex-direction: column;
    }
  }
  .home-about__bg {
    background-size: cover;
  }

  .home-about__title {
    font-size: 24px;
  }

  .home-about__box {
    padding: 24px 16px;
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
    max-width: none;
  }
  .home-about__points{
    max-width: none;
  }

  .home-about__box-link {
    margin-top: 16px;
  }

  .home-about__box-btn {
    margin-top: 16px;
  }

}

@media screen and (max-width: 768px){
  .home-about {
    padding-top: 90px;
    padding-bottom: 140px;
  }

  .home-about__bg {
    background: url('../../../assets/images/matrix-m.png') center center repeat-x;
    background-size: contain;
    &--2{
      top: 19px;
    }
  }

  .home-about__grid {
    flex-direction: column-reverse;
  }

  .home-about__img {
    max-width: 300px;
    min-width: auto;
  }

  .home-about__content {
    margin-top: 28px;
    margin-right: 0;
  }

  .home-about__title {
    margin-bottom: 16px;
    text-align: center;
  }
}


@media screen and (max-width: 480px){
  .home-about__img {
    max-width: 210px;
  }
  .home-about__points{
  }
}
